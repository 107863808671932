import React, { Component, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import setAuthToken from "../lib/setAuthToken";
import { Link, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import config from "../lib/config";
import Web3 from "web3";
import { toast } from "react-toastify";
import DropdownButton from "react-bootstrap/DropdownButton";
import BUSDcontractABI from "../ABI/BUSDcontractABI.json";
//
import { useEthersSigner, walletClientToSigner } from "../ethersConnect";

import {
  // type PublicClient,
  // type WalletClient,
  useWalletClient,
  usePublicClient,
  useDisconnect,
  useConnect,
  useNetwork,
  useAccount,
  useSwitchNetwork,
} from "wagmi";
import { connection } from "../helper/connection";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../reducers/Actions";

let toasterOption = config.toasterOption;

export default function Navbar() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [BNBBalnce, setBNBBalnce] = useState(0);
  const [BUSDblns, setBUSDblns] = useState(0);

  const walletConnection = useSelector((state) => state.walletConnection);
  console.log(walletConnection, "walletConnection");
  // const { connect, connectors, error, isLoading, pendingConnector } =
  //   useConnect();
  // const { disconnect } = useDisconnect();
  // const { chain, chains } = useNetwork();
  const dispatch = useDispatch();

  // useAccount({
  //   onConnect: ({ address, connector, isReconnected }) => {
  //     console.log("Connected", { address, connector, isReconnected });
  //   },
  //   onDisconnect: () => {
  //     console.log("Disconnected");
  //     var web3 = new Web3(config.rpcUrl);
  //     dispatch(
  //       setWallet({
  //         network: config.NetworkId,
  //         web3: web3,
  //         address: "",
  //         provider: null,
  //         connect: "",
  //         isChange: "",
  //       })
  //     );
  //     setTimeout(function () {
  //       window.location.reload(false);
  //     }, 1500);
  //   },
  // });

  let chainId = config.NetworkId;
  // const { data: walletClient } = useWalletClient({ chainId });

  // useEffect(() => {
  //   setConnection();
  // }, [walletClient, chain?.network]);

  // async function setConnection() {
  //   if (walletClient && chain && chain.id == config.NetworkId) {
  //     var { signer, transport } = walletClientToSigner(walletClient);
  //     var web3 = new Web3(transport);
  //     if (chain.id == config.NetworkId) {
  //       dispatch(
  //         setWallet({
  //           network: config.NetworkId,
  //           web3: web3,
  //           address: walletClient.account.address,
  //           provider: transport,
  //           connect: "yes",
  //           isChange: "false",
  //         })
  //       );
  //     }
  //   } else {
  //     var web3 = new Web3("https://bsc-dataseed1.binance.org");
  //     dispatch(
  //       setWallet({
  //         network: config.NetworkId,
  //         web3: web3,
  //         address: "",
  //         provider: null,
  //         connect: "",
  //         isChange: "",
  //       })
  //     );
  //   }
  // }

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
  const toggleRightSidebar = () => {
    document.querySelector(".right-sidebar").classList.toggle("open");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    window.open(metamaskAppDeepLink, "_self");
  };

  // async function connectfunction(connector) {
  //   setShow(false);
  //   try {
  //     let check = isMobile();

  //     if (
  //       check &&
  //       !window.ethereum &&
  //       connector &&
  //       connector.connector.id != "walletConnect"
  //     ) {
  //       await connectMetamaskMobile();
  //       return;
  //     }
  //     if (window.ethereum) {
  //       var web3 = new Web3(window.ethereum);
  //       var currnetwork = await web3.eth.net.getId();
  //       console.log(currnetwork, "currnetworkc", config.NetworkId);
  //       if (
  //         parseInt(currnetwork) != config.NetworkId &&
  //         connector.connector.id == "metaMask"
  //       ) {
  //         toast.error("Please Connect BNB Network", toasterOption);
  //         return;
  //       }
  //     }

  //     var resp = connect(connector);
  //     window.$("#connect_wallet_modal").modal("hide");
  //     setTimeout(function () {
  //       //window.location.reload(false);
  //     }, 1000);
  //   } catch (err) {}
  // }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 15) + "...." + addr.slice(addr.length - 7);
    } else {
      return "";
    }
  };

  const Logout = () => {
    localStorage.removeItem("admin_token");
    history.push("/user-pages/login-1");
    setAuthToken("");
  };

  // async function disconnectMetamask() {
  //   var token = localStorage.getItem("admin_token");
  //   localStorage.clear();
  //   localStorage.setItem("admin_token", token);

  //   disconnect();

  //   setTimeout(function () {
  //     window.location.reload(false);
  //   }, 1000);
  // }

  // useEffect(() => {
  //   getconnect();
  // }, [walletConnection, walletClient]);

  // async function getconnect() {
  //   if (
  //     walletConnection &&
  //     walletConnection.web3 &&
  //     walletConnection.address &&
  //     walletConnection.address != undefined &&
  //     walletConnection.address != "" &&
  //     walletConnection.network != ""
  //   ) {
  //     let address = walletConnection.address;
  //     var web3 = walletConnection.web3;

  //     if (web3 && address) {
  //       try {
  //         if (web3 !== undefined) {
  //           // getBNB balance
  //           var balance = await web3.eth.getBalance(address);
  //           var currbal = balance / config.decimals;
  //           setBNBBalnce(currbal.toFixed(4));

  //           //getTokenbalnce
  //           var BUSDcontract = new web3.eth.Contract(
  //             BUSDcontractABI,
  //             config.BUSDcontract
  //           );

  //           let Tokenbalnce = await BUSDcontract.methods
  //             .balanceOf(walletConnection.address)
  //             .call();
  //           var bal = Tokenbalnce / config.decimals;
  //           setBUSDblns(bal.toFixed(4));
  //         }
  //       } catch (err) {}
  //     }
  //   }
  // }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          {/* <img
            style={{ filter: "brightness(0)" }}
            src={require("../../assets/images/logo.svg")}
            alt="logo"
          /> */}
          Admin
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          {/* <img src={require('../../assets/images/logo-mini.svg')} alt="logo" /> */}
          Admin
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="mdi mdi-menu"></span>
        </button>
        {/* <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input
                  type="text"
                  className="form-control bg-transparent border-0"
                  placeholder="Search projects"
                />
              </div>
            </form>
          </div> */}
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img">
                  <img
                    src={require("../../assets/images/faces-clipart/pic-4.png")}
                    alt="user"
                  />
                  {/* <span className="availability-status online"></span> */}
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">
                    <Trans>Admin</Trans>
                  </p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                {/* <Dropdown.Item
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <i className="mdi mdi-cached mr-2 text-success"></i>
                    <Trans>Activity Log</Trans>
                  </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => {
                    history.push("/ChangePassword");
                  }}
                >
                  <i className="mdi mdi-key mr-2 text-primary"></i>
                  <Trans>Change Password</Trans>
                </Dropdown.Item>
                <Dropdown.Item onClick={Logout}>
                  <i className="mdi mdi-logout mr-2 text-primary"></i>
                  <Trans>Signout</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          {/* {walletConnection && walletConnection.address == "" ? (
            <Button variant="primary" onClick={handleShow}>
              Connect Wallet
            </Button>
          ) : (
            <DropdownButton
              id="dropdown-button-dark-example2"
              variant="primary"
              title={halfAddrShow(walletConnection?.address)}
              className="mt-2"
              data-bs-theme="dark"
            >
              <Dropdown.Item>Balance in BNB : {BNBBalnce}</Dropdown.Item>
              <Dropdown.Item>Balance in BUSD : {BUSDblns}</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => disconnectMetamask()}>
                Disconnect
              </Dropdown.Item>
            </DropdownButton>
          )} */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Connect Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <Row>
                {connectors &&
                  connectors.length > 0 &&
                  connectors.map((connector) => (
                    <>
                      {connector && connector.id == "metaMask" ? (
                        <Col style={{ marginLeft: "14px" }}>
                          <Button
                            variant="outline-danger"
                            onClick={() => connectfunction({ connector })}
                          >
                            <img
                              src={require("../../assets/images/metamask.png")}
                              height="28"
                              width="28"
                              className="img-fluid"
                            />{" "}
                            Metamask
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {connector && connector.id == "walletConnect" ? (
                        <Col>
                          <Button
                            variant="outline-info"
                            onClick={() => connectfunction({ connector })}
                          >
                            <img
                              src={require("../../assets/images/trustwallet.png")}
                              height="28"
                              width="28"
                              className="img-fluid"
                            />{" "}
                            Trust Wallet
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
              </Row> */}
            </Modal.Body>
          </Modal>

          {/* <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="mdi mdi-email-outline"></i>
                  <span className="count-symbol bg-warning"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <h6 className="p-3 mb-0">
                    <Trans>Messages</Trans>
                  </h6>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <img
                        src={require("../../assets/images/faces/face4.jpg")}
                        alt="user"
                        className="profile-pic"
                      />
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                        <Trans>Mark send you a message</Trans>
                      </h6>
                      <p className="text-gray mb-0">
                        1 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <img
                        src={require("../../assets/images/faces/face2.jpg")}
                        alt="user"
                        className="profile-pic"
                      />
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                        <Trans>Cregh send you a message</Trans>
                      </h6>
                      <p className="text-gray mb-0">
                        15 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <img
                        src={require("../../assets/images/faces/face3.jpg")}
                        alt="user"
                        className="profile-pic"
                      />
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                        <Trans>Profile picture updated</Trans>
                      </h6>
                      <p className="text-gray mb-0">
                        18 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <h6 className="p-3 mb-0 text-center cursor-pointer">
                    4 <Trans>new messages</Trans>
                  </h6>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="mdi mdi-bell-outline"></i>
                  <span className="count-symbol bg-danger"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <h6 className="p-3 mb-0">
                    <Trans>Notifications</Trans>
                  </h6>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="mdi mdi-calendar"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        <Trans>Event today</Trans>
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        <Trans>
                          Just a reminder that you have an event today
                        </Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="mdi mdi-settings"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        <Trans>Settings</Trans>
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        <Trans>Update dashboard</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="mdi mdi-link-variant"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        <Trans>Launch Admin</Trans>
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        <Trans>New admin wow</Trans>!
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <h6 className="p-3 mb-0 text-center cursor-pointer">
                    <Trans>See all notifications</Trans>
                  </h6>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item nav-logout d-none d-lg-block">
              <a
                className="nav-link"
                href="!#"
                onClick={(event) => event.preventDefault()}
              >
                <i className="mdi mdi-power"></i>
              </a>
            </li>
            <li className="nav-item nav-settings d-none d-lg-block">
              <button
                type="button"
                className="nav-link border-0"
                onClick={this.toggleRightSidebar}
              >
                <i className="mdi mdi-format-line-spacing"></i>
              </button>
            </li> */}
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

// class Navbar extends Component {

//   render() {
//     return (

//     );
//   }
// }

// export default Navbar;
