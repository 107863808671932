import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";
import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const UserHistory = lazy(() => import("./History/UserHistory.js"));
const ListFAQ = lazy(() => import("./FAQ/ListFAQ.js"));
const AddFAQ = lazy(() => import("./FAQ/AddFAQ.js"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const Login = lazy(() => import("./user-pages/Login"));
const depositHistory = lazy(() => import("./History/DepositHistory"));
const withdrawHistory = lazy(() => import("./History/WithdrawHistory"));
const TransactionHistory = lazy(() => import("./History/TransactionHistory"));
const Coinlist = lazy(() => import("./Coinlist/coinlist"));
// const viewKyc = lazy(() => import("./Viewuser/Kycview"));
const viewApi = lazy(() => import("./Viewuser/Apiview"));
const viewAddress = lazy(() => import("./Viewuser/Addressview"));
const emailtemplates = lazy(() => import("./emailTemplate/emailTemplate"));
const emailtemplateslist = lazy(() => import("./emailTemplate/emailTemplateList"));

const cmsdata = lazy(() => import("./cms/cmsdata"));
const cmslist = lazy(() => import("./cms/cmslist"));
const supportcategory = lazy(() => import("./supportcategory/category"));
const supportlist = lazy(() => import("./supportticket/supportlist"));
const ticketchat = lazy(() => import("./supportticket/ticketchat"));



export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <ConditionRoute
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />

        <ConditionRoute
          path="/FAQ/ListFAQ"
          component={ListFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/FAQ/AddFAQ"
          component={AddFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/ChangePassword"
          component={ChangePassword}
          type={"private"}
        />

        <ConditionRoute
          path="/merchant/UserHistory"
          component={UserHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/History/depositHistory"
          component={depositHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/History/withdrawHistory"
          component={withdrawHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/History/transactionHistory"
          component={TransactionHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/Coinlist"
          component={Coinlist}
          type={"private"}
        />
       
        <ConditionRoute
          path="/viewApi/:id"
          component={viewApi}
          type={"private"}
        />

        <ConditionRoute
          path="/viewAddress/:id"
          component={viewAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/emailtemplates/:id"
          component={emailtemplates}
          type={"private"}
        />
        <ConditionRoute
          path="/emailtemplates"
          component={emailtemplates}
          type={"private"}
        />
        <ConditionRoute
          path="/emailtemplateslist"
          component={emailtemplateslist}
          type={"private"}
        />


        <ConditionRoute
          path="/cmsdata/:id"
          component={cmsdata}
          type={"private"}
        />
        <ConditionRoute
          path="/cmsdata"
          component={cmsdata}
          type={"private"}
        />
        <ConditionRoute
          path="/cmslist"
          component={cmslist}
          type={"private"}
        />
        <ConditionRoute
          path="/supportcategory"
          component={supportcategory}
          type={"private"}
        />

        <ConditionRoute
          path="/supportlist"
          component={supportlist}
          type={"private"}
        />
        <ConditionRoute
          path="/ticketchat/:ticketId"
          component={ticketchat}
          type={"private"}
        />


        <ConditionRoute
          path="/user-pages/login-1"
          component={Login}
          type={"auth"}
        />

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
