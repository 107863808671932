let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

let Envname = "demo";
if (Envname == "demo") {
  const API_URL = "https://dopaminecpgapi.wealwindemo.com"; //DEMO

  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    BUSDcontract: "0x1c922249c720C04395B1C7e512a5482052AeeCeD",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    NetworkId: 97,
    decimals: 1e18,
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    toasterOption: toasterOption,
    BUSDcontract: "0x1c922249c720C04395B1C7e512a5482052AeeCeD",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    NetworkId: 97,
    decimals: 1e18,
  };
}

export default key;
