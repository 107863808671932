import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import SettingsPanel from "./shared/SettingsPanel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./shared/Footer";
import { withTranslation } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';

//
import { Provider } from "react-redux";
import store from "./store";
import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { bsc, bscTestnet, polygon, polygonMumbai } from "viem/chains";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [bsc, bscTestnet],
// [/*publicProvider()*/]
// );
// console.log({ chains }, "chainschainschainswwwww");

// Set up wagmi config
// const config = createConfig({
//   autoConnect: true,
//   connectors: [
//     new MetaMaskConnector({
//       chains,
//       shimDisconnect: true,
//       shimChainChangedDisconnect: true,
//     }),
//     new WalletConnectConnector({
//       chains,
//       shimDisconnect: true,
//       shimChainChangedDisconnect: true,
//       options: {
//         projectId: "681700e231a5aef269b7fe4adb34981a",
//         version: "2",
//       },
//     }),
//     new InjectedConnector({
//       chains,
//       shimDisconnect: true,
//       shimChainChangedDisconnect: true,
//       options: {
//         name: "Injected",
//       },
//     }),
//   ],
//   publicClient,
//   webSocketPublicClient,
// });

class App extends Component {
  state = {};
  componentDidMount() {
    this.onRouteChanged();
  }
  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : "";
    let SettingsPanelComponent = !this.state.isFullPageLayout ? (
      <SettingsPanel />
    ) : (
      ""
    );
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <Provider store={store}>
        {/* <WagmiConfig config={config}> */}
          <div className="container-scroller">
            <ToastContainer />
            {navbarComponent}
            <div className="container-fluid page-body-wrapper">
              {sidebarComponent}
              <div className="main-panel">
                <div className="content-wrapper">
                  <AppRoutes />
                  {SettingsPanelComponent}
                </div>
                {footerComponent}
              </div>
            </div>
          </div>
        {/* </WagmiConfig> */}
      </Provider>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    const { i18n } = this.props;
    const body = document.querySelector("body");
    if (this.props.location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
      i18n.changeLanguage("ar");
    } else {
      body.classList.remove("rtl");
      i18n.changeLanguage("en");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/user-pages/login-1",
      "/user-pages/register-1",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withTranslation()(withRouter(App));
